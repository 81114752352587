<template>
  <v-container fluid>
    <v-form ref="form" @submit.prevent="validateBeforeSubmit">
      <v-col cols="12">
        <v-text-field
          v-model="vm.accountsPaidBy"
          label="Accounts paid by"
          v-validate="'required'"
          data-vv-as="Accounts paid by"
          name="accountsPaidBy"
          :error-messages="errors.collect('accountsPaidBy')"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="vm.contactPerson"
          label="Contact person"
          v-validate="'required'"
          data-vv-as="Contact person"
          name="contactPerson"
          :error-messages="errors.collect('contactPerson')"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="vm.phoneNumber"
          label="Phone number"
          v-validate="'required'"
          data-vv-as="Phone number"
          name="phoneNumber"
          :error-messages="errors.collect('phoneNumber')"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="vm.email"
          label="Email"
          v-validate="'required'"
          data-vv-as="Email"
          name="email"
          :error-messages="errors.collect('email')"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <address-picker
          :detail-mode="false"
          label="Postal address"
          :min-characters="4"
          v-model="vm.postalAddress"
          :required="true"
          v-validate="'required'"
          data-vv-as="postalAddress"
          name="postalAddress"
          :error-messages="errors.collect('postalAddress')"
        ></address-picker>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="vm.personsignature"
          label="Person's signature"
          v-validate="'required'"
          data-vv-as="Person signature"
          name="personsignature"
          :error-messages="errors.collect('personsignature')"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="vm.nameOfSignatory"
          label="Name of signatory"
          v-validate="'required'"
          data-vv-as="Name of signatory"
          name="nameOfsignatory"
          :error-messages="errors.collect('nameOfSignatory')"
        ></v-text-field>
      </v-col>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="vm.date"
        transition="scale-transition"
        offset-y
        full-width
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="vm.date"
            label="Date"
            readonly
            v-on="on"
            v-validate="'required'"
            data-vv-as="date"
            name="date"
            :error-messages="errors.collect('date')"
          ></v-text-field>
        </template>
        <v-date-picker v-model="vm.date" no-title scrollable color="green lighten-1" dark>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(vm.date)">OK</v-btn>
        </v-date-picker>
      </v-menu>
      <v-col cols="12" v-if="isAdmin">
        <v-radio-group
          v-model="vm.hasRestrictedAccess"
          label="Do you want to restrict access to this participant's information?"
          v-validate="'required'"
          name="access"
          :error-messages="errors.collect('access')"
        >
          <v-radio label="Yes" v-bind:value="true" ></v-radio>
          <v-radio label="No" v-bind:value="false" ></v-radio>
        </v-radio-group>
      </v-col>
     
      <v-col cols="12" v-if="vm.hasRestrictedAccess">
        <p v-if="isAdmin"> If yes display “please select users that can access this participant information” </p>
        <multiselect
          v-if="isAdmin"
          v-model="vm.authorizedUsers"
          :options="users"
          :multiple="true"
          :close-on-select="false"
          :hide-selected="true"
          placeholder="Select Users"
          label="email"
          track-by="email"
         
        >
        </multiselect>
      
      </v-col>
      <v-col cols="12"></v-col>
      <v-btn color="primary" type="submit">Continue</v-btn>
    </v-form>
  </v-container>
</template>

<script>
var Models = require("./models.js");
import Vue from "vue";
import Multiselect from "vue-multiselect";

import VeeValidate from "vee-validate";
import AddressPicker from "../../../components/core/AddressPicker";
import * as userService from "../../../services/userService";
Vue.use(VeeValidate);
export default {
  $_veeValidate: {
    validator: "new",
  },
  components: {
    AddressPicker,
    Multiselect,
  },
  name: "signature-form",
  data: () => ({
    vm: new Models.Signature(),
    form: {},
    menu: false,
    hasRestrictedAccess: false,
    isAdmin: false,
    picked: false,
    users: [],
    authorizedUsers: [],
    
  }),
  props: {
    msg: String,
  },
  mounted() {
    this.init();
  },
  computed: {
    currentCase() {
      return this.$store.getters.currentCase;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    async init() {
      this.vm =
        this.$store.getters.currentCase == undefined ||
        this.$store.getters.currentCase.signatures == undefined
          ? {}
          : this.$store.getters.currentCase.signatures;
      if (
        this.user.roles.indexOf("System Administrator") > -1 ||
        this.user.roles.indexOf("Organisation Administrator") > -1
      ) {
        this.isAdmin = true;
      }
      let users = [];
      let filteredUsers = [];
      userService.getByOrgId(this.currentOrganisation.id).then((result) => {
        result.docs.map((doc) => {
          const user = doc.data();
          user.id = doc.id;
          users.push(user);
        });

        for (let i = 0; i < users.length; i++) {
          if (
            users[i].roles.indexOf("System Administrator") == -1 &&
            users[i].roles.indexOf("Organisation Administrator") == -1
          ) {
            let obj={}
            obj["id"] = users[i].id;
            obj["displayName"]=users[i].displayName;
            obj["email"]=users[i].email;
            filteredUsers.push(obj);
            obj={};
          }
        }
       // console.log(filteredUsers);
        this.users = filteredUsers;
       
       
      });
    }, 
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
       
        this.currentCase.signatures = this.vm;

          this.currentCase.status = "Submitted";
          this.$store.dispatch("setCurrentCase", this.currentCase);
          this.$router.push("/form-success");
          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4",
          });
        }
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="vue-multiselect/dist/vue-multiselect.min.css">
.multiselect {
  border-top-color: #156a59;
}
</style>
<style scoped></style>
