export class Case {
  constructor(participant) {
    if (participant == null) {
      participant = new ParticipantDetails();
    }
    this.participant = participant;
  }
}

export class MedicalContacts {
  constructor(
    contactType,
    name,
    email,
    workPhone,
    mobilePhone,
    residentialAddressLine1,
    suburbStatePostcode1,
    isPostalAddressthesame,
    postalAddress,
    suburbStatePostcode2,
    tetanus,
    dateLastReceived1,
    hepatitis,
    dateLastReceived2
  ) {
    this.contactType = contactType;
    this.name = name;
    this.email = email;
    this.workPhone = workPhone;
    this.mobilePhone = mobilePhone;
    this.residentialAddressLine1 = residentialAddressLine1;
    this.suburbStatePostcode1 = suburbStatePostcode1;
    this.isPostalAddressthesame = isPostalAddressthesame;
    this.postalAddress = postalAddress;
    this.suburbStatePostcode2 = suburbStatePostcode2;
    this.tetanus = tetanus;
    this.dateLastReceived1 = dateLastReceived1;
    this.hepatitis = hepatitis;
    this.dateLastReceived2 = dateLastReceived2;
  }
}

export class Accounts {
  constructor(
    accountsPaidBy,
    contactPerson,
    phoneNumber,
    postalAddress,
    suburbStatePostcode
  ) {
    this.accountsPaidBy = accountsPaidBy;
    this.contactPerson = contactPerson;
    this.phoneNumber = phoneNumber;
    this.postalAddress = postalAddress;
    this.suburbStatePostcode = suburbStatePostcode;
  }
}

export class CarerDetails {
  constructor(
    existenceOfCarer,
    relationshipOfClientToCarer,
    doesCarerAssistServiceUser,
    nameOfCarer,
    carerResidencyStatus,
    carerAgeBracket,
    domesticLife
  ) {
    this.existenceOfCarer = existenceOfCarer;
    this.relationshipOfClientToCarer = relationshipOfClientToCarer;
    this.doesCarerAssistServiceUser = doesCarerAssistServiceUser;
    this.nameOfCarer = nameOfCarer;
    this.carerResidencyStatus = carerResidencyStatus;
    this.carerAgeBracket = carerAgeBracket;
    this.domesticLife = domesticLife;
  }
}

export class Contact {
  constructor(
    name,
    homeNumber,
    workNumber,
    mobileNumber,
    email,
    preferredContactMethod,
    dateOfBirth,
    address,
    isPostalTheSame,
    postalAddress,
    relationship
  ) {
    this.name = name;
    this.homeNumber = homeNumber;
    this.workNumber = workNumber;
    this.mobileNumber = mobileNumber;
    this.email = email;
    this.preferredContactMethod = preferredContactMethod;
    this.dateOfBirth = dateOfBirth;
    this.address = address;
    this.isPostalTheSame = isPostalTheSame;
    this.postalAddress = postalAddress;
    this.relationship = relationship;
  }
}

/* 
                           _          __  __   _       _                   
                          | |        / _|/ _| (_)     | |                  
 _ __   _____      __  ___| |_ _   _| |_| |_   _ ___  | |__   ___ _ __ ___ 
| '_ \ / _ \ \ /\ / / / __| __| | | |  _|  _| | / __| | '_ \ / _ \ '__/ _ \
| | | |  __/\ V  V /  \__ \ |_| |_| | | | |   | \__ \ | | | |  __/ | |  __/
|_| |_|\___| \_/\_/   |___/\__|\__,_|_| |_|   |_|___/ |_| |_|\___|_|  \___|
                                                                           
*/

/*========================================================================================
______          _   _      _                   _       
| ___ \        | | (_)    (_)                 | |      
| |_/ /_ _ _ __| |_ _  ___ _ _ __   __ _ _ __ | |_ ___ 
|  __/ _` | '__| __| |/ __| | '_ \ / _` | '_ \| __/ __|
| | | (_| | |  | |_| | (__| | |_) | (_| | | | | |_\__ \
\_|  \__,_|_|   \__|_|\___|_| .__/ \__,_|_| |_|\__|___/
                            | |                        
                            |_|   
                            
                            */

export class ParticipantDetails {
  constructor(
    firstname,
    preferredName,
    lastname,
    email,
    mobileNumber,
    homeNumber,
    dateOfBirth,
    address,
    isPostalTheSame,
    postalAddress,
    medicareNumber,
    cRNNumber,
    nDISNumber,
    sWSNumber,
    taxFileNumber,
    maritalStatus,
    gender,
    primaryDisability,
    secondaryDisability,
    notes,
    isConsentedToDSNMDSCollections,
    hasConsentedToTakePhotos,
    personGivingConsentPhotos,
    personGivingConsentGov,
    hasConsentedoShareWithParents,
    personGivingConsentParents,
    livingArrangements,
    isSpecialAccommodation,
    otherOccupants,
    weight,
    hairColour,
    eyeColour,
    distinguishingFeatures,
    indigenousStatus,
    countryOfBirth,
    residencyStatus,
    ethnicity,
    individualFundingStatus,
    height,
    mainIncomeSource,
    communicationMethod,
    interpreterServices,
    primaryLanguage,
    labourForceStatus,
    communicationImpairments,
    languagesSpoken,
    recentWorkParticipation,
    timeSincePaidEmployment,
    hasCriminalConvictionJuvenileWithin5Years,
    hasCriminalConvictionAdultWithin10Years,
    hasConsentedToShareWithGov,
    hascustodialSentenceMoreThan1month
  ) {
    this.firstname = firstname;
    this.preferredName = preferredName;
    this.lastname = lastname;
    this.email = email;
    this.mobileNumber = mobileNumber;
    this.homeNumber = homeNumber;
    this.dateOfBirth = dateOfBirth;
    this.address = address;
    this.isPostalTheSame = isPostalTheSame;
    this.postalAddress = postalAddress;
    this.medicareNumber = medicareNumber;
    this.cRNNumber = cRNNumber;
    this.nDISNumber = nDISNumber;
    this.sWSNumber = sWSNumber;
    this.taxFileNumber = taxFileNumber;
    this.maritalStatus = maritalStatus;
    this.primaryDisability = primaryDisability;
    this.secondaryDisability = secondaryDisability;
    this.notes = notes;
    this.gender = gender;
    this.isConsentedToDSNMDSCollections = isConsentedToDSNMDSCollections;
    this.hasConsentedToTakePhotos = hasConsentedToTakePhotos;
    this.personGivingConsentPhotos = personGivingConsentPhotos;
    this.hasConsentedToShareWithGov = hasConsentedToShareWithGov;
    this.personGivingConsentGov = personGivingConsentGov;
    this.hasConsentedoShareWithParents = hasConsentedoShareWithParents;
    this.personGivingConsentParents = personGivingConsentParents;
    this.livingArrangements = livingArrangements;
    this.isSpecialAccommodation = isSpecialAccommodation;
    this.otherOccupants = otherOccupants;
    this.weight = weight;
    this.hairColour = hairColour;
    this.eyeColour = eyeColour;
    this.distinguishingFeatures = distinguishingFeatures;
    this.indigenousStatus = indigenousStatus;
    this.countryOfBirth = countryOfBirth;
    this.residencyStatus = residencyStatus;
    this.ethnicity = ethnicity;
    this.individualFundingStatus = individualFundingStatus;
    this.height = height;
    this.mainIncomeSource = mainIncomeSource;
    this.communicationMethod = communicationMethod;
    this.interpreterServices = interpreterServices;
    this.primaryLanguage = primaryLanguage;
    this.labourForceStatus = labourForceStatus;
    this.communicationImpairments = communicationImpairments;
    this.languagesSpoken = languagesSpoken;
    this.recentWorkParticipation = recentWorkParticipation;
    this.timeSincePaidEmployment = timeSincePaidEmployment;
    this.hasCriminalConvictionJuvenileWithin5Years = hasCriminalConvictionJuvenileWithin5Years;
    this.hasCriminalConvictionAdultWithin10Years = hasCriminalConvictionAdultWithin10Years;
    this.hascustodialSentenceMoreThan1month = hascustodialSentenceMoreThan1month;
  }
}

/*===============================================================================
 _____                              _   _   _               _     
/  ___|                            | | | \ | |             | |    
\ `--. _   _ _ __  _ __   ___  _ __| |_|  \| | ___  ___  __| |___ 
 `--. \ | | | '_ \| '_ \ / _ \| '__| __| . ` |/ _ \/ _ \/ _` / __|
/\__/ / |_| | |_) | |_) | (_) | |  | |_| |\  |  __/  __/ (_| \__ \
\____/ \__,_| .__/| .__/ \___/|_|   \__\_| \_/\___|\___|\__,_|___/
            | |   | |                                             
            |_|   |_|                                         

*/

export class SupportNeeds {
  constructor(
    selfCare,
    mobility,
    communication,
    interpersonalInteractionsAndRelationships,
    learningApplyingKnowledgeAndGeneralTasksDemands,
    education,
    communicationCivicAndEconomicLife,
    toileting,
    eatingAndDrinking,
    isOnSpecialDiet,
    isHomeAloneAssessed,
    activitiesEnjoyed,
    activitiesNotEnjoyed,
    activitiesClientCannotDoDueToMedicalReasons,
    isAffectedByNoise,
    noisesThatAffect,
    isAffectByCrowds,
    crowdsThatAffect,
    isAffectedByAnimals,
    AnimalsThatAffect,
    isAffectedByFireworks,
    isAffectedByAnythingElse,
    anythingElseThatAffects,
    isRequiredOutingSupport,
    isAtRiskOfFallsOrTrips,
    isAbleToAttendOutingsWithoutSupport,
    listActivities,
    isResponsibleForOwnMoneyOnOutings,
    isWheelChairAccessRequired,
    isAbleToBeInvolvedInWaterSports,
    listWaterSportIssues,
    isAwareOfRoadSafety,
    isAbleToTravelIndependently,
    isAwareOfStrangerDanger,
    isLikelyToWanderOff,
    isAbleToGoOutInSmallGroupsWithSupportStaff,
    isAbleToRemainSeatedWhilstTraveling,
    isLikelyToRemoveSeatBelt,
    hasActivitiesRequirementWhilstTraveling,
    travelingActivities,
    listOfTravelActivities,
    recommenedMaxTravelTime,
    hasWarningSignsOfBehaviourDifficulties,
    listWarningSigns,
    hasGotPBSP
  ) {
    this.selfCare = selfCare;
    this.mobility = mobility;
    this.communication = communication;
    this.interpersonalInteractionsAndRelationships = interpersonalInteractionsAndRelationships;
    this.learningApplyingKnowledgeAndGeneralTasksDemands = learningApplyingKnowledgeAndGeneralTasksDemands;
    this.education = education;
    this.communicationCivicAndEconomicLife = communicationCivicAndEconomicLife;
    this.toileting = toileting;
    this.eatingAndDrinking = eatingAndDrinking;
    this.isOnSpecialDiet = isOnSpecialDiet;
    this.isHomeAloneAssessed = isHomeAloneAssessed;
    this.activitiesEnjoyed = activitiesEnjoyed;
    this.activitiesNotEnjoyed = activitiesNotEnjoyed;
    this.activitiesClientCannotDoDueToMedicalReasons = activitiesClientCannotDoDueToMedicalReasons;
    this.isAffectedByNoise = isAffectedByNoise;
    this.noisesThatAffect = noisesThatAffect;
    this.isAffectByCrowds = isAffectByCrowds;
    this.crowdsThatAffect = crowdsThatAffect;
    this.isAffectedByAnimals = isAffectedByAnimals;
    this.AnimalsThatAffect = AnimalsThatAffect;
    this.isAffectedByFireworks = isAffectedByFireworks;
    this.isAffectedByAnythingElse = isAffectedByAnythingElse;
    this.anythingElseThatAffects = anythingElseThatAffects;
    this.isRequiredOutingSupport = isRequiredOutingSupport;
    this.isAtRiskOfFallsOrTrips = isAtRiskOfFallsOrTrips;
    this.isAbleToAttendOutingsWithoutSupport = isAbleToAttendOutingsWithoutSupport;
    this.listActivities = listActivities;
    this.isResponsibleForOwnMoneyOnOutings = isResponsibleForOwnMoneyOnOutings;
    this.isWheelChairAccessRequired = isWheelChairAccessRequired;
    this.isAbleToBeInvolvedInWaterSports = isAbleToBeInvolvedInWaterSports;
    this.listWaterSportIssues = listWaterSportIssues;
    this.isAwareOfRoadSafety = isAwareOfRoadSafety;
    this.isAbleToTravelIndependently = isAbleToTravelIndependently;
    this.isAwareOfStrangerDanger = isAwareOfStrangerDanger;
    this.isLikelyToWanderOff = isLikelyToWanderOff;
    this.isAbleToGoOutInSmallGroupsWithSupportStaff = isAbleToGoOutInSmallGroupsWithSupportStaff;
    this.isAbleToRemainSeatedWhilstTraveling = isAbleToRemainSeatedWhilstTraveling;
    this.isLikelyToRemoveSeatBelt = isLikelyToRemoveSeatBelt;
    this.hasActivitiesRequirementWhilstTraveling = hasActivitiesRequirementWhilstTraveling;
    this.travelingActivities = travelingActivities;
    this.listOfTravelActivities = listOfTravelActivities;
    this.recommenedMaxTravelTime = recommenedMaxTravelTime;
    this.hasWarningSignsOfBehaviourDifficulties = hasWarningSignsOfBehaviourDifficulties;
    this.listWarningSigns = listWarningSigns;
    this.hasGotPBSP = hasGotPBSP;
  }
}
/*==========================================================================================
___  ___         _ _           _ 
|  \/  |        | (_)         | |
| .  . | ___  __| |_  ___ __ _| |
| |\/| |/ _ \/ _` | |/ __/ _` | |
| |  | |  __/ (_| | | (_| (_| | |
\_|  |_/\___|\__,_|_|\___\__,_|_|
*/

export class Medical {
  constructor(
    privateHealthInsuranceDetails,
    hasDrugAlcoholAddiction,
    isMedicalConsentMinor,
    minorDate,
    clientGivenMinor,
    personGivenMinor,
    isMedicalConsentMajor,
    majorDate,
    clientGivenMajor,
    personGivenMajor,
    isSpecialAccommodation,
    isSmoker,
    hasSeizurePlan,
    doSpecialCareNursingRequired,
    nutritionSwalloingIssues,
    clientMedicaton,
    medicationType,
    medicationName,
    dosage,
    method,
    medicationTime,
    medicalReason,
    medicationmethod,
    possibleReactions,
    procedureMissed,
    prescribingDr,
    drPhoneNumber,
    datePrescribed,
    dateStarted,
    medCondition,
    comments,
    doTakeMedication,
    medicalNotes,
    prefferredPainRelief,
    allergiesAndReactions,
    treatments,
    contactType,
    name,
    email,
    workPhone,
    mobilePhone,
    residentialAddressLine1,
    suburbStatePostcode1,
    isPostalAddressthesame,
    postalAddress,
    suburbStatePostcode2,
    tetanus,
    tetanusDateLastReceived,
    hepatitis,
    hepatitisDateLastReceived,
    isMedicationImapirMyAbility,
    items
  ) {
    this.privateHealthInsuranceDetails = privateHealthInsuranceDetails;
    this.hasDrugAlcoholAddiction = hasDrugAlcoholAddiction;
    this.isMedicalConsentMinor = isMedicalConsentMinor;
    this.minorDate = minorDate;
    this.clientGivenMinor = clientGivenMinor;
    this.personGivenMinor = personGivenMinor;
    this.isMedicalConsentMajor = isMedicalConsentMajor;
    this.majorDate = majorDate;
    this.clientGivenMajor = clientGivenMajor;
    this.personGivenMajor = personGivenMajor;
    this.isSpecialAccommodation = isSpecialAccommodation;
    this.isSmoker = isSmoker;
    this.hasSeizurePlan = hasSeizurePlan;
    this.doSpecialCareNursingRequired = doSpecialCareNursingRequired;
    this.nutritionSwalloingIssues = nutritionSwalloingIssues;
    this.medicationType = medicationType;
    this.medicationName = medicationName;
    this.dosage = dosage;
    this.method = method;
    this.medicationTime = medicationTime;
    this.medicalReason = medicalReason;
    this.medicationmethod = medicationmethod;
    this.possibleReactions = possibleReactions;
    this.procedureMissed = procedureMissed;
    this.prescribingDr = prescribingDr;
    this.drPhoneNumber = drPhoneNumber;
    this.datePrescribed = datePrescribed;
    this.dateStarted = dateStarted;
    this.clientMedicaton = clientMedicaton;
    this.medCondition = medCondition;
    this.comments = comments;
    this.isMedicationImapirMyAbility = isMedicationImapirMyAbility;
    this.doTakeMedication = doTakeMedication;
    this.medicalNotes = medicalNotes;
    this.prefferredPainRelief = prefferredPainRelief;
    this.allergiesAndReactions = allergiesAndReactions;
    this.treatments = treatments;
    this.tetanus = tetanus;
    this.tetanusDateLastReceived = tetanusDateLastReceived;
    this.hepatitis = hepatitis;
    this.hepatitisDateLastReceived = hepatitisDateLastReceived;
    this.contactType = contactType;
    this.name = name;
    this.email = email;
    this.workPhone = workPhone;
    this.mobilePhone = mobilePhone;
    this.residentialAddressLine1 = residentialAddressLine1;
    this.suburbStatePostcode1 = suburbStatePostcode1;
    this.isPostalAddressthesame = isPostalAddressthesame;
    this.postalAddress = postalAddress;
    this.suburbStatePostcode2 = suburbStatePostcode2;
    this.items = items;
  }
}

/*
 _____                _                                  _ _____         _       _             _____    _                 _   _             
|  ___|              | |                                | |_   _|       (_)     (_)           |  ___|  | |               | | (_)            
| |__ _ __ ___  _ __ | | ___  _   _ _ __ ___   ___ _ __ | |_| |_ __ __ _ _ _ __  _ _ __   __ _| |__  __| |_   _  ___ __ _| |_ _  ___  _ __  
|  __| '_ ` _ \| '_ \| |/ _ \| | | | '_ ` _ \ / _ \ '_ \| __| | '__/ _` | | '_ \| | '_ \ / _` |  __|/ _` | | | |/ __/ _` | __| |/ _ \| '_ \ 
| |__| | | | | | |_) | | (_) | |_| | | | | | |  __/ | | | |_| | | | (_| | | | | | | | | | (_| | |__| (_| | |_| | (_| (_| | |_| | (_) | | | |
\____/_| |_| |_| .__/|_|\___/ \__, |_| |_| |_|\___|_| |_|\__\_/_|  \__,_|_|_| |_|_|_| |_|\__, \____/\__,_|\__,_|\___\__,_|\__|_|\___/|_| |_|
               | |             __/ |                                                      __/ |                                             
               |_|            |___/                                                      |___/       

*/

export class EmploymentTrainingEducation {
  constructor(
    highestLevelOfSchooling,
    listAnyOtherQualifications,
    hasAnyOtherQualifications,
    isAbleToStillUseQualification,
    literacyAndNumeracySkills,
    listOtherQualifications
  ) {
    this.highestLevelOfSchooling = highestLevelOfSchooling;
    this.listAnyOtherQualifications = listAnyOtherQualifications;
    this.hasAnyOtherQualifications = hasAnyOtherQualifications;
    this.isAbleToStillUseQualification = isAbleToStillUseQualification;
    this.literacyAndNumeracySkills = literacyAndNumeracySkills;
    this.listOtherQualifications = listOtherQualifications;
  }
}

/*
______             _    _             
| ___ \           | |  (_)            
| |_/ / __ _ _ __ | | ___ _ __   __ _ 
| ___ \/ _` | '_ \| |/ / | '_ \ / _` |
| |_/ / (_| | | | |   <| | | | | (_| |
\____/ \__,_|_| |_|_|\_\_|_| |_|\__, |
                                 __/ |
                                |___/ 
*/

/*Banking Heading*/

export class Banking {
  constructor(
    buildingSociety,
    accountName,
    accountNumber,
    bsbNumber,
    tfnNumber,
    superFundName,
    superMemberNumber
  ) {
    this.buildingSociety = buildingSociety;
    this.accountName = accountName;
    this.accountNumber = accountNumber;
    this.bsbNumber = bsbNumber;
    this.tfnNumber = tfnNumber;
    this.superFUndName = superFundName;
    this.superMemberNumber = superMemberNumber;
  }
}

/*Signature*/
export class Signature {
  constructor(
    accountsPaidBy,
    contactPerson,
    phoneNumber,
    postalAddress,
    suburbStatePostcode,
    personsignature,
    date
  ) {
    this.accountsPaidBy = accountsPaidBy;
    this.contactPerson = contactPerson;
    this.phoneNumber = phoneNumber;
    this.postalAddress = postalAddress;
    this.suburbStatePostcode = suburbStatePostcode;
    this.personsignature = personsignature;
    this.date = date;
  }
}

export class Notes {
  constructor(
    date,
    worker,
    supportProvided,
    relevantGoal,
    startDate,
    endDate,
    isMultiDateEvent,
    commencementTime,
    finishTime,
    billedTime,
    notesProgress,
    notesOther,
    followUpRequired,
    details,
    followUpCompleted,
    staffName,
    signature
  ) {
    this.date = date;
    this.worker = worker;
    this.supportProvided = supportProvided;
    this.relevantGoal = relevantGoal;
    this.startDate = startDate;
    this.endDate = endDate;
    this.isMultiDateEvent = isMultiDateEvent;
    this.commencementTime = commencementTime;
    this.finishTime = finishTime;
    this.billedTime = billedTime;
    this.notesProgress = notesProgress;
    this.notesOther = notesOther;
    this.followUpRequired = followUpRequired;
    this.details = details;
    this.followUpCompleted = followUpCompleted;
    this.staffName = staffName;
    this.signature = signature;
  }
}

export class Feedback {
  constructor(
    typeOfFeedback,
    date,
    time,
    personProvidingFeedback,
    personName,
    respondersName,
    responseProvidedToFeedback,
    typeOfResponse,
    dateClosed
  ) {
    this.typeOfFeedback = typeOfFeedback;
    this.date = date;
    this.time = time;
    this.personProvidingFeedback = personProvidingFeedback;
    this.personName = personName;
    this.respondersName = respondersName;
    this.responseProvidedToFeedback = responseProvidedToFeedback;
    this.typeOfResponse = typeOfResponse;
    this.dateClosed = dateClosed;
  }
}

export class Complaint {
  constructor(
    fullName,
    nameOfSupportingPerson,
    whatIsRelationshipToPerson,
    isPersonKnowAboutComplaint,
    isCompalintConsentWithPerson,
    nameOfRepresntative,
    organisation,
    postalAddress,
    contactNumber,
    email,
    myPrefferedContact,
    whatIsComplaintAbout,
    registrationGroupComplainingAbout,
    personaRegistrationGroup,
    outcomesSeeking,
    supportInfo,
    complaintMadeBefore,
    consentProvidingInfoToJudriction
  ) {
    this.fullName = fullName;
    this.nameOfSupportingPerson = nameOfSupportingPerson;
    this.whatIsRelationshipToPerson = whatIsRelationshipToPerson;
    this.isPersonKnowAboutComplaint = isPersonKnowAboutComplaint;
    this.isCompalintConsentWithPerson = isCompalintConsentWithPerson;
    this.nameOfRepresntative = nameOfRepresntative;
    this.organisation = organisation;
    this.postalAddress = postalAddress;
    this.contactNumber = contactNumber;
    this.email = email;
    this.myPrefferedContact = myPrefferedContact;
    this.whatIsComplaintAbout = whatIsComplaintAbout;
    this.registrationGroupComplainingAbout = registrationGroupComplainingAbout;
    this.personaRegistrationGroup = personaRegistrationGroup;
    this.outcomesSeeking = outcomesSeeking;
    this.supportInfo = supportInfo;
    this.complaintMadeBefore = complaintMadeBefore;
    this.consentProvidingInfoToJudriction = consentProvidingInfoToJudriction;
  }
}

export class serviceAgreement {
  constructor(
    nameOfTheParticipant,
    nDISNumber,
    startDate,
    copyOfNdisPlanAttached,
    serviceArea,
    budget,
    details,
    supportPaid,
    contactPerson,
    contactPersonPosition,
    contactPersonNumber,
    contactPersonEmail,
    anotherContactName,
    anotherContactPositionHeld,
    anotherContactPhone,
    anotherContactEmail,
    participantDaytimePhone,
    participantEveningPhone,
    mobileNumber,
    email,
    participantaddress,
    participantAnotherContactName,
    participantanotherContactPhone,
    organisationPhoneNumber,
    organisationMobileNumber,
    organisationEmail,
    organisationAddress,
    signingParticipantName,
    signingParticipantSign,
    signingParticipantDate,
    witnessDate,
    witnessSignature,
    participantDate,
    serviceProvider,
    serviceRepresentative,
    serviceSignature,
    serivceProviderDate,
    checklistServiceAgreement


  ) {
    this.nameOfTheParticipant = nameOfTheParticipant;
    this.nDISNumber = nDISNumber;
    this.startDate = startDate;
    this.copyOfNdisPlanAttached = copyOfNdisPlanAttached;
    this.serviceArea = serviceArea;
    this.budget = budget;
    this.details = details;
    this.supportPaid = supportPaid;
    this.contactPerson = contactPerson;
    this.contactPersonPosition = contactPersonPosition;
    this.contactPersonNumber = contactPersonNumber;
    this.contactPersonEmail = contactPersonEmail;
    this.anotherContactName = anotherContactName;
    this.anotherContactPositionHeld = anotherContactPositionHeld;
    this.anotherContactPhone = anotherContactPhone;
    this.anotherContactEmail = anotherContactEmail;
    this.participantDaytimePhone = participantDaytimePhone;
    this.participantEveningPhone = participantEveningPhone;
    this.mobileNumber = mobileNumber;
    this.email = email;
    this.participantaddress = participantaddress;
    this.participantAnotherContactName = participantAnotherContactName;
    this.participantanotherContactPhone = participantanotherContactPhone;
    this.organisationPhoneNumber = organisationPhoneNumber;
    this.organisationMobileNumber = organisationMobileNumber;
    this.organisationEmail = organisationEmail;
    this.organisationAddress = organisationAddress;
    this.signingParticipantName = signingParticipantName;
    this.signingParticipantSign = signingParticipantSign;
    this.signingParticipantDate = signingParticipantDate;
    this.witnessDate = witnessDate;
    this.witnessSignature = witnessSignature;
    this.participantDate = participantDate;
    this.serviceProvider = serviceProvider;
    this.serviceRepresentative = serviceRepresentative;
    this.serviceSignature = serviceSignature;
    this.serivceProviderDate = serivceProviderDate;
    this.checklistServiceAgreement = checklistServiceAgreement;

  }
}
